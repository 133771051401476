.outline{
    border-radius: 25px;
    max-width: 1100px;
    margin:30px auto;
    text-align: left;
    padding: 30px;
    background-color: #141342b8;
}
a{
    color: lightblue;
}
.side-by-side{
    display: flex;
    justify-content:space-evenly;
    align-items: center;
}
.side-by-side > img{
    width: 30%;
    aspect-ratio: 500 / 411;
    object-fit: contain;

}
.background{
    background-color: #141342b8;
    border-radius: 25px;
    max-width: 1100px;
    margin:30px auto;
    padding: 30px;
}
h2{
    margin-bottom: 0px;
}
img{
    max-width: 80%;
}