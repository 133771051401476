.App {
  text-align: center;
  color: white;
}
body{
  /* background: linear-gradient(180deg, #02004B 30%, rgba(255, 255, 255, 0) 100%); */
  background-image: url("images/seattlebackground.jpg");
  background-color: #263064;
  /* mix-blend-mode: normal; */
  background-repeat: no-repeat;
  background-size:cover;
  background-position: bottom;
  background-attachment: fixed;
}
#sub-tittle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-logo {
  margin-left: 20px;
  margin-right: 20px;
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  
  background-color: #141342;
  min-height: 7vh;
  font-size: 2vmin;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position:sticky;
  top: 0px;
  z-index: 3;
}
.links a{
  margin: 10px;
}
.links{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
#tittle-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#site-name , #tag-line{
  margin: 0;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* typing effect*/
.typewriter h2 {
  max-width: fit-content;
  width: fit-content;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .10em solid white; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .10em; /* Adjust as needed */
  animation: 
    typing 6s steps(75, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}

.links a:visited {
  color: white !important; /* Change the color of visited links */
  text-decoration: none !important; /* Remove underline from visited links */
}
.links a{
  text-decoration: none;
}


a{
  color: lightblue;
}
a:hover{
  color: rgb(52, 52, 222);
}

footer {
  margin-top: 100px;
  margin-bottom: 0;
  
}
