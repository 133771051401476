.card-img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    grid-row: 1;
    grid-column: 1;
    z-index: -1;
    margin:0 auto;
}
/*For cards  */
.card-container {
    width: 300px;
    height: 300px;
    margin: 15px;
    display: grid;
    justify-content: center;
    align-items: center;
    /* border:solid 1px #33aaff; */
  }
  .card-holder {
    height: max-content;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: space-around;
  }
  #working-on-line {
    margin-top:20px;
    margin-bottom:20px;
    font-size: 40px;
  }
  .card-content {
    padding: 10px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.1s;

    border-radius: 20%;
    border-width: 2px;
    width: 300px;
    height: 300px;
    color: white;
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content:center ;

    grid-row: 1;
    grid-column: 1;
  }
  
  .card-container:hover .card-content {
  opacity: 1;
  background-color: #141342b8;
}
