body {
  margin: 0;
  font-family: 'Mochiy Pop One';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
section{
  margin: 30px;
}